<template>
  <form
    class="edit-profile-main"
    @submit.prevent="submit"
  >
    <div class="edit-profile-main__row">
      <ui-text
        v-model.trim="profile.email"
        class="edit-profile-main__field"
        name="edit-profile-email"
        :placeholder="$t('FIRST_NAME_LBL')"
        :is-disabled="isSubmitting"
        readonly
      />
      <ui-text
        v-model.trim="profile.phoneNumber"
        class="edit-profile-main__field"
        name="edit-profile-email"
        :placeholder="$t('FIRST_NAME_LBL')"
        :is-disabled="isSubmitting"
        readonly
      />
    </div>

    <div class="edit-profile-main__row">
      <ui-text
        v-model.trim="form.firstName"
        class="edit-profile-main__field"
        name="edit-profile-first-name"
        :placeholder="$t('FIRST_NAME_LBL')"
        :is-error="errors.firstName !== ''"
        :is-disabled="isSubmitting"
        @input="errors.firstName = ''"
      >
        <span slot="error">
          {{ $t(errors.firstName) }}
        </span>
      </ui-text>

      <ui-text
        v-model.trim="form.lastName"
        class="edit-profile-main__field"
        name="edit-profile-last-name"
        :placeholder="$t('LAST_NAME_LBL')"
        :is-error="errors.lastName !== ''"
        :is-disabled="isSubmitting"
        @input="errors.lastName = ''"
      >
        <span slot="error">
          {{ $t(errors.lastName) }}
        </span>
      </ui-text>
    </div>

    <list-params-location
      class="edit-profile-main__location"
      v-model="form.location"
      :is-disabled="isSubmitting"
      label=" "
      :placeholder="$t('LOCATION_LBL')"
    />

    <div class="edit-profile-main__actions">
      <ui-button
        type="submit"
        class="edit-profile-main__submit-btn"
        :is-disabled="isSubmitting"
      >
        {{ $t('UPDATE_PROFILE_BTN') }}
      </ui-button>
    </div>
  </form>
</template>

<script>
import { userGetters, userActions } from 'Store/entities/User/types'
import { mapGetters, mapActions } from 'vuex'

import { UiText, UiButton } from '@shelf.network/ui-kit'
import { ListParamsLocation } from 'Common/ListParams'

import { showError, showSuccess } from 'Utils/notifications'

export default {
  name: 'edit-profile-main',
  components: {
    UiText,
    UiButton,
    ListParamsLocation,
  },
  data () {
    return {
      isSubmitting: false,

      form: {
        firstName: '',
        lastName: '',
        location: '',
      },

      errors: {
        firstName: '',
        lastName: '',
      },
    }
  },
  computed: {
    ...mapGetters('entities/user', {
      profile: userGetters.PROFILE,
    }),
  },
  created () {
    this.form.firstName = this.profile.firstName
    this.form.lastName = this.profile.lastName
    this.form.location = this.getLocationString()
  },
  methods: {
    ...mapActions('entities/user', {
      updateProfile: userActions.UPDATE_PROFILE,
    }),
    async submit () {
      if (!this.validateForm()) {
        return
      }

      this.isSubmitting = true

      try {
        await this.updateProfile({
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          ...this.getLocationObject(),
        })
        showSuccess(this.$t('PROFILE_CHANGED_MSG'))
      } catch (e) {
        console.error(e)
        showError(this.$t('TOASTS.UNEXPECTED_ERROR'))
      }

      this.isSubmitting = false
    },
    validateForm () {
      let isValid = true

      if (!this.form.firstName) {
        this.errors.firstName = 'FIELD_REQUIRED_ERROR'
        isValid = false
      }

      if (!this.form.lastName) {
        this.errors.lastName = 'FIELD_REQUIRED_ERROR'
        isValid = false
      }

      return isValid
    },
    getLocationObject () {
      const [regionId, countryId, cityId] = this.form.location.split(':')

      return {
        region: regionId || '0',
        countryId: countryId ? Number(countryId) : 0,
        cityId: cityId ? Number(cityId) : 0,
      }
    },
    getLocationString () {
      return [
        this.profile.region || '0',
        this.profile.countryId || '0',
        this.profile.cityId || '0',
      ].join(':')
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-profile-main {
  display: grid;
  grid-gap: 1.875em;

  /deep/ &__field .ui-text__input[readonly],
  /deep/ &__field .ui-text__input[readonly]:hover {
    box-shadow: none;
    color: $color-ui-secondary;
    cursor: not-allowed;
  }

  &__row {
    align-items: start;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    grid-gap: 0.5em;
  }
}
</style>

<i18n>
{
  "en": {
    "FIRST_NAME_LBL": "First name",
    "LAST_NAME_LBL": "Last name",
    "EMAIL_LBL": "Email",
    "PHONE_LBL": "Phone",
    "UPDATE_PROFILE_BTN": "Update profile",
    "FIELD_REQUIRED_ERROR": "This field is required",
    "LOCATION_LBL": "Location",
    "PROFILE_CHANGED_MSG": "Profile updated!"
  },
  "ka": {
    "FIRST_NAME_LBL": "სახელი",
    "LAST_NAME_LBL": "გვარი",
    "EMAIL_LBL": "ელ.ფოსტა",
    "PHONE_LBL": "ტელეფონი",
    "UPDATE_PROFILE_BTN": "პროფილის განახლება",
    "FIELD_REQUIRED_ERROR": "აუცილებელი ველი",
    "LOCATION_LBL": "ლოკაცია",
    "PROFILE_CHANGED_MSG": "პროფილი განახლდა!"
  },
  "ru": {
    "FIRST_NAME_LBL": "Имя",
    "LAST_NAME_LBL": "Фамилия",
    "EMAIL_LBL": "Электронная почта",
    "PHONE_LBL": "Телефон",
    "UPDATE_PROFILE_BTN": "Обновить профиль",
    "FIELD_REQUIRED_ERROR": "Это поле обязательно к заполнению",
    "LOCATION_LBL": "Місце розташування",
    "PROFILE_CHANGED_MSG": "Профиль обновлен!"
  },
  "uk": {
    "FIRST_NAME_LBL": "Ім’я",
    "LAST_NAME_LBL": "Прізвище",
    "EMAIL_LBL": "Електронна пошта",
    "PHONE_LBL": "Телефон",
    "UPDATE_PROFILE_BTN": "Оновити профіль",
    "FIELD_REQUIRED_ERROR": "Це поле є обов`язковим для заповнення",
    "LOCATION_LBL": "Местонахождение",
    "PROFILE_CHANGED_MSG": "Профіль оновлено!"
  }
}
</i18n>
