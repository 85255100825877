<template>
  <div class="edit-profile-avatar">
    <ui-avatar
      class="edit-profile-avatar__avatar"
      :src="profile.avatarLink"
      look="secondary"
    />

    <div class="edit-profile-avatar__actions">
      <ui-button
        class="edit-profile-avatar__btn"
        :is-disabled="isSubmitting"
        @click="updateAvatar"
      >
        {{
          profile.avatarLink ? $t('AVATAR_CHANGE_BTN') : $t('AVATAR_UPLOAD_BTN')
        }}
      </ui-button>

      <ui-button
        v-if="profile.avatarLink"
        class="
            edit-profile-avatar__btn
            edit-profile-avatar__btn_danger
          "
        :is-disabled="isSubmitting"
        fill="none"
        @click="removeAvatar"
      >
        {{ $t('AVATAR_REMOVE_BTN') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { UiAvatar, UiButton } from '@shelf.network/ui-kit'
import { selectImage } from 'Utils/selectFile'
import { showError, showSuccess } from 'Utils/notifications'

import { userActions, userGetters } from 'Store/entities/User/types'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'edit-profile-avatar',

  components: {
    UiAvatar,
    UiButton,
  },

  data: _ => ({
    isSubmitting: false,
  }),

  computed: {
    ...mapGetters('entities/user', {
      profile: userGetters.PROFILE,
    }),
  },

  methods: {
    ...mapActions('entities/user', {
      changeAvatar: userActions.CHANGE_AVATAR,
      removeAvatar: userActions.REMOVE_AVATAR,
    }),
    async updateAvatar () {
      const image = await selectImage()
      if (!image) {
        return
      }

      this.isSubmitting = true

      try {
        await this.changeAvatar(image)
        showSuccess(this.$t('PROFILE_CHANGED_MSG'))
      } catch (e) {
        showError(this.$t('AVATAR_UPLOAD_ERROR'))
      }

      this.isSubmitting = false
    },
  },
}
</script>

<style lang="scss" scoped>

.edit-profile-avatar {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1.875em;
  margin: 0 auto;
  max-width: 20em;
  width: 100%;
  justify-items: center;

  &__avatar {
    @include respond(font-size, 200px, 180px, 160px, 140px);
  }

  &__actions {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__btn {
    @include respond(font-size, 15px, 14px, 13px, 12px);

    &:not(:last-child) {
      margin-bottom: 1.25em;
    }

    &_danger {
      $col: $color-flag-is-error;

      .ui-button__button[fill][look] {
        color: $col;

        &:hover {
          background: rgba($col, 0.12);
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "AVATAR_CHANGE_BTN": "Upload new",
    "AVATAR_REMOVE_BTN": "Delete photo",
    "AVATAR_UPLOAD_BTN": "Upload a photo",
    "AVATAR_UPLOAD_ERROR": "Error occurred while uploading a photo",
    "PROFILE_CHANGED_MSG": "Profile updated!"
  },
  "ka": {
    "AVATAR_CHANGE_BTN": "ატვირთვა",
    "AVATAR_REMOVE_BTN": "სურათის წაშლა",
    "AVATAR_UPLOAD_BTN": "სურათის ატვირთვა",
    "AVATAR_UPLOAD_ERROR": "ფოტოს ჩატვირთვისას მოხდა შეცდომა",
    "PROFILE_CHANGED_MSG": "პროფილი განახლდა!"
  },
  "ru": {
    "AVATAR_CHANGE_BTN": "Заменить",
    "AVATAR_REMOVE_BTN": "Удалить",
    "AVATAR_UPLOAD_BTN": "Загрузить фото",
    "AVATAR_UPLOAD_ERROR": "Произошла ошибка при загрузке фото",
    "PROFILE_CHANGED_MSG": "Профиль обновлен!"
  },
  "uk": {
    "AVATAR_CHANGE_BTN": "Замінити",
    "AVATAR_REMOVE_BTN": "Вилучити",
    "AVATAR_UPLOAD_BTN": "Завантажити фото",
    "AVATAR_UPLOAD_ERROR": "Сталася помилка під час завантаження фото",
    "PROFILE_CHANGED_MSG": "Профіль оновлено!"
  }
}
</i18n>
