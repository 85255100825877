<template>
  <form
    class="edit-profile-password"
    @submit.prevent="submit"
  >
    <input
      style="display: none;"
      autocomplete="username"
      name="username"
      :value="profile.email"
    >
    <!-- see https://goo.gl/9p2vKq -->

    <ui-text
      v-model="form.oldPassword"
      class="edit-profile-password__field"
      type="password"
      autocomplete="current-password"
      :is-disabled="isSubmitting"
      :is-error="errors.oldPassword !== ''"
      :placeholder="$t('PASSWORD_OLD_LBL')"
      @input="errors.oldPassword = ''"
    >
      <span slot="error">
        {{ $t(errors.oldPassword) }}
      </span>
    </ui-text>

    <ui-text
      class="edit-profile-password__field"
      autocomplete="new-password"
      type="password"
      v-model="form.newPassword"
      :is-disabled="isSubmitting"
      :is-error="errors.newPassword !== ''"
      :placeholder="$t('PASSWORD_NEW_LBL')"
      @input="errors.newPassword = ''; errors.confirmNewPassword = ''"
    >
      <span slot="error">
        {{ $t(errors.newPassword) }}
      </span>
    </ui-text>

    <ui-text
      autocomplete="new-password"
      class="edit-profile-password__field"
      type="password"
      v-model="form.confirmNewPassword"
      :is-disabled="isSubmitting"
      :is-error="errors.confirmNewPassword !== ''"
      :placeholder="$t('PASSWORD_NEW_AGAIN_LBL')"
      @input="errors.confirmNewPassword = ''"
    >
      <span slot="error">
        {{ $t(errors.confirmNewPassword) }}
      </span>
    </ui-text>

    <div class="edit-profile-main__actions">
      <ui-button
        type="submit"
        class="edit-profile-password__btn"
        :is-disabled="isSubmitting"
      >
        {{ $t('PASSWORD_CHANGE_BTN') }}
      </ui-button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { userGetters, userActions } from 'Store/entities/User/types'
import { UiText, UiButton } from '@shelf.network/ui-kit'
import { showError, showSuccess } from 'Utils/notifications'
import { JsonApiNotAllowedError } from '@shelf.network/js-sdk'

export default {
  name: 'edit-profile-password',
  components: {
    UiText,
    UiButton,
  },
  data: _ => ({
    isSubmitting: false,
    form: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    errors: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    }
  }),
  computed: {
    ...mapGetters('entities/user', {
      profile: userGetters.PROFILE,
    }),
  },
  methods: {
    ...mapActions('entities/user', {
      updatePassword: userActions.UPDATE_PASSWORD,
    }),
    async submit () {
      if (!this.validateForm()) {
        return
      }

      this.isSubmitting = true

      try {
        await this.updatePassword({
          oldPassword: this.form.oldPassword,
          newPassword: this.form.newPassword,
        })
        showSuccess(this.$t('PASSWORD_CHANGED_MSG'))
      } catch (e) {
        if (e instanceof JsonApiNotAllowedError) {
          this.errors.oldPassword = 'PASSWORD_WRONG_ERR'
        } else {
          console.error(e)
          showError(this.$t('TOASTS.UNEXPECTED_ERROR'))
        }
      }

      this.isSubmitting = false
    },
    validateForm () {
      let isValid = true

      if (this.form.oldPassword === '') {
        this.errors.oldPassword = 'FIELD_REQUIRED_ERROR'
        isValid = false
      }

      if (this.form.newPassword === '') {
        this.errors.newPassword = 'FIELD_REQUIRED_ERROR'
        isValid = false
      }

      if (this.form.confirmNewPassword === '') {
        this.errors.confirmNewPassword = 'FIELD_REQUIRED_ERROR'
        isValid = false
      }

      const MIN_PASSWORD_LENGTH = 8
      const MAX_PASSWORD_LENGTH = 64

      if (
        this.form.newPassword !== '' &&
        (
          this.form.newPassword.length < MIN_PASSWORD_LENGTH ||
          this.form.newPassword.length > MAX_PASSWORD_LENGTH
        )
      ) {
        this.errors.newPassword = 'PASSWORD_FORMAT_ERROR'
        isValid = false
      }

      if (
        this.form.newPassword !== '' &&
        this.form.confirmNewPassword !== '' &&
        this.form.newPassword !== this.form.confirmNewPassword
      ) {
        this.errors.confirmNewPassword = 'PASSWORD_MISMATCH_ERR'
        isValid = false
      }

      return isValid
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-profile-password {
  display: grid;
  grid-gap: 1.875em;
}
</style>

<i18n>
{
  "en": {
    "PASSWORD_OLD_LBL": "Current password",
    "PASSWORD_NEW_LBL": "New password",
    "PASSWORD_NEW_AGAIN_LBL": "New password again",
    "PASSWORD_CHANGE_BTN": "Change password",
    "PASSWORD_MISMATCH_ERR": "New passwords are mismatched",
    "PASSWORD_WRONG_ERR": "Wrong current password",
    "PASSWORD_FORMAT_ERROR": "The password should be min 8 chars and max 64 chars length",
    "FIELD_REQUIRED_ERROR": "This field is required",
    "PASSWORD_CHANGED_MSG": "Your password has been updated successfully"
  },
  "ka": {
    "PASSWORD_OLD_LBL": "არსებული პაროლი ",
    "PASSWORD_NEW_LBL": "ახალი პაროლი",
    "PASSWORD_NEW_AGAIN_LBL": "ახალი პაროლი ",
    "PASSWORD_CHANGE_BTN": "პაროლის შეცვლა",
    "PASSWORD_MISMATCH_ERR": "ახალი პაროლი არ ემთხვევა",
    "PASSWORD_WRONG_ERR": "არასწორი პაროლი",
    "PASSWORD_FORMAT_ERROR": "პაროლი უნდა იყოს 8 სიმბოლოზე მეტი",
    "FIELD_REQUIRED_ERROR": "აუცილებელი ველი",
    "PASSWORD_CHANGED_MSG": "თქვენი პაროლი შეიცვალა"
  },
  "ru": {
    "PASSWORD_OLD_LBL": "Текущий пароль",
    "PASSWORD_NEW_LBL": "Новый пароль",
    "PASSWORD_NEW_AGAIN_LBL": "Новый пароль повторно",
    "PASSWORD_CHANGE_BTN": "Сменить пароль",
    "PASSWORD_MISMATCH_ERR": "Нові паролі не збігаються",
    "PASSWORD_WRONG_ERR": "Невірний поточний пароль",
    "PASSWORD_FORMAT_ERROR": "Пароль должен содержать не менее 8 символов и не более 64 символов",
    "FIELD_REQUIRED_ERROR": "Это поле обязательно к заполнению",
    "PASSWORD_CHANGED_MSG": "Ваш пароль был изменен"
  },
  "uk": {
    "PASSWORD_OLD_LBL": "Поточний пароль",
    "PASSWORD_NEW_LBL": "Новий пароль",
    "PASSWORD_NEW_AGAIN_LBL": "Новий пароль повторно",
    "PASSWORD_CHANGE_BTN": "Змінити пароль",
    "PASSWORD_MISMATCH_ERR": "Новые пароли не совпадают",
    "PASSWORD_WRONG_ERR": "Неверный текущий пароль",
    "PASSWORD_FORMAT_ERROR": "Пароль повинен бути не менше 8 символів і не більше 64 символів у довжину",
    "FIELD_REQUIRED_ERROR": "Це поле є обов`язковим для заповнення",
    "PASSWORD_CHANGED_MSG": "Ваш пароль було змінено"
  }
}
</i18n>
