<template>
  <div class="edit-profile">
    <ui-container class="edit-profile__container">
      <template v-if="isLoaded">
        <div class="edit-profile__group">
          <edit-profile-avatar />
        </div>
        <div class="edit-profile__group">
          <label class="edit-profile__group-title">
            {{ $t('YOUR_INFO_HEADING') }}
          </label>
          <edit-profile-main />
        </div>
        <div class="edit-profile__group">
          <label class="edit-profile__group-title">
            {{ $t('PASSWORD_HEADING') }}
          </label>
          <edit-profile-password />
        </div>
      </template>

      <template v-else>
        <edit-profile-skeleton />
      </template>
    </ui-container>
  </div>
</template>

<script>
import EditProfileAvatar from './components/EditProfileAvatar'
import EditProfileMain from './components/EditProfileMain'
import EditProfilePassword from './components/EditProfilePassword'
import EditProfileSkeleton from './components/EditProfileSkeleton'

import { UiContainer } from '@shelf.network/ui-kit'

import { mapActions } from 'vuex'
import { userActions } from 'Store/entities/User/types'

export default {
  name: 'edit-profile',
  components: {
    UiContainer,

    EditProfileSkeleton,
    EditProfileAvatar,
    EditProfileMain,
    EditProfilePassword,
  },
  data: _ => ({
    isLoaded: false,
  }),
  async created () {
    await this.getProfile()
    this.isLoaded = true
  },
  methods: {
    ...mapActions('entities/user', {
      getProfile: userActions.TRY_GET_PROFILE_DATA,
    })
  },
}
</script>

<style lang="scss" scoped>
.edit-profile {
  &__container {
    padding-top: 3.75em;
    width: 100%;
    max-width: 39rem;
    display: grid;
    grid-gap: 5em;
  }

  &__group {
    display: grid;
    grid-gap: 1.875em;

    &-title {
      display: block;
      font-size: 1.4em;
      font-weight: 500;
      line-height: 1.5;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "PASSWORD_HEADING": "Password",
    "YOUR_INFO_HEADING": "Your info"
  },
  "ka": {
    "PASSWORD_HEADING": "პაროლი",
    "YOUR_INFO_HEADING": "თქვენი ინფორმაცია"
  },
  "ru": {
    "PASSWORD_HEADING": "Пароль",
    "YOUR_INFO_HEADING": "Ваша информация"
  },
  "uk": {
    "PASSWORD_HEADING": "Пароль",
    "YOUR_INFO_HEADING": "Ваша інформація"
  }
}
</i18n>
