export async function selectImage () {
  return selectFile(['image/png', 'image/jpeg'])
}

export async function selectPDF () {
  return selectFile(['application/pdf'])
}

export async function selectFile (fileTypes = []) {
  const input = document.createElement('input')
  input.setAttribute('style', 'display: none;')
  input.setAttribute('type', 'file')

  if (fileTypes.length) {
    input.setAttribute('accept', fileTypes.join(','))
  }

  document.body.append(input)
  input.click()

  // eslint-disable-next-line promise/avoid-new
  return new Promise(resolve => {
    input.addEventListener('change', event => {
      input.remove()

      const file = event.target.files[0]
      if (fileTypes.length && !fileTypes.includes(file.type)) {
        resolve()
      }

      resolve(file)
    })
  })
}
